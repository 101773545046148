<template>
  <div class="d-flex flex-column align-center">
    <slot name="title"></slot>
    <div class="chart-parent">
      <div class="chart-text d-flex flex-column align-center justify-center">
        <slot name="inner-text"></slot>
      </div>
      <canvas ref="chart"></canvas>
    </div>
    <slot name="result"></slot>
    <div
      class="px-1 my-1 d-flex align-center justify-space-between flex-wrap"
      v-if="showLabels"
    >
      <!-- chart labels here !!! -->
      <div class="ma-2" v-for="(label, index) in labels" :key="index">
        <div class="d-flex ">
          <!-- label color -->
          <div
            class="color-thumbanil mr-1"
            :style="{ background: label.color }"
          ></div>
          <div class="">
            <p class="label mr-3">{{ label.text }}</p>
            <div class="sublabel  d-flex items-center justify-start">
              {{ label.price | formatMoney }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Chart, ArcElement, PieController, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
export default {
  mounted() {
    if (this.todayMonthlyReports?.costs != 0) {
      this.initPieChart();
    }
  },
  props: {
    showLabels: {
      type: Boolean,
      required: true
    },
    labels: {
      type: Array,
      required: true
    },
    todayMonthlyReports: Object
  },
  methods: {
    initPieChart() {
      Chart.register(PieController, ArcElement, Tooltip);
      const doughnutChart = new Chart(this.$refs.chart, {
        type: "doughnut",
        data: {
          labels: Array.prototype.map.call(this.labels, val =>
            val?.short_text ? val.short_text : val.text
          ),
          datasets: [
            {
              label: "Shop sale data",
              data: Array.prototype.map.call(
                this.labels,
                val => val.percentage
              ),
              datalabels: {
                formatter: function(value, context) {
                  return (
                    context.chart.data.labels[context.dataIndex] +
                    `\n ${value}` +
                    `%`
                  );
                }
              },
              backgroundColor: Array.prototype.map.call(
                this.labels,
                val => val.color
              ),
              hoverOffset: 4
            }
          ]
        },
        plugins: [ChartDataLabels]
      });
    }
  }
};
</script>
<style scoped>
.subtitle {
  font-size: 12px;
}
.title {
  font-size: 16px;
}
.label-parent {
  width: 70px;
  background-color: aquamarine;
}
.color-thumbanil {
  width: 13px;
  height: 13px;
}
.label {
  font-size: 12px;
  margin: 0;
}
.sublabel {
  font-size: 10px;
}
.chart-parent {
  height: 171px;
  width: 171px;
  border-radius: 50%;
  position: relative;
  background-color: transparent;
}
.chart-text {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
canvas {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
}
</style>
